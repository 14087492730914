<div>
  @if (form) {
    <div [formGroup]="form">
      <div class="row-adaptive">
        <div class="col-adaptive">
          <app-search-input
            placeholder="{{ 'placeholders.filterByCounterpartyTypeName' | transloco }}"
            formControlName="counterpartyName"
            class="filter-control"
          ></app-search-input>
        </div>
        <div class="col-adaptive plus-btn-col ml-auto">
          <app-button
            automation-id="create-btn"
            class="w100"
            icon="add"
            [type]="btnTypes.Icon"
            [size]="btnSizes.InFilterRow"
            [title]="'buttons.addDocType' | transloco"
            (onClick)="addCounterparty()"
          ></app-button>
        </div>
      </div>
    </div>
  }
  
  @if (dataRecieved && form) {
    <app-counterparties-list class="app-counterparties-table"></app-counterparties-list>
  }
</div>
