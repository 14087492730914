import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  AfterContentChecked,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, combineLatest, filter } from 'rxjs';
import { AlertType } from 'src/app/shared/components/app-alert/app-alert.component';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/state';
import { isPresent } from 'src/app/core/utils/isPresent';
import {
  selectCloseEditingCounterpartyModal,
  selectCounterparty,
  selectCounterpartyEditing,
  selectCounterpartyLoading,
} from '../../../store/selectors';
import { PartyDto } from '../../../services/parties-api.service';
import { CounterpartiesActions } from '../../../store/actions';

@UntilDestroy()
@Component({
  selector: 'app-edit-counterparty-modal',
  templateUrl: './edit-counterparty-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCounterpartyModalComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @Input() id!: string;
  public onClose: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup | undefined;
  public title: string = this.translocoService.translate(`titles.editCounterparty`);
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public alertTypes = AlertType;
  public creatingNewCounterparty: boolean = false;
  public isCounterpartyLoading$ = this.store$.select(selectCounterpartyLoading);
  public isCounterpartyEditing$ = this.store$.select(selectCounterpartyEditing);
  public counterparty: PartyDto | null | undefined;

  constructor(
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterContentChecked(): void {}

  ngAfterViewInit(): void {
    this.store$
      .select(selectCloseEditingCounterpartyModal)
      .pipe(untilDestroyed(this))
      .subscribe((close) => {
        if (close) {
          this.bsModalRef.hide();
          this.store$.dispatch(CounterpartiesActions.clearEditCounterpartyCancelled());
        }
      });
  }

  public ngOnInit(): void {
    this.isCounterpartyLoading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      if (loading === false) {
        this.initForm();
      }
    });
  }

  initForm(): void {
    combineLatest([this.store$.select(selectCounterparty).pipe(filter(isPresent))])
      .pipe(untilDestroyed(this))
      .subscribe(([counterparty]) => {
        this.counterparty = counterparty;

        this.form = this.fb.group({
          name: [this.counterparty?.name, { validators: [Validators.required] }],
        });

        this.cdr.markForCheck();
      });
  }

  public onConfirm(): void {
    const model = this.form?.getRawValue();

    this.store$.dispatch(CounterpartiesActions.editCounterparty({ model, id: this.id }));
  }

  public onCancel(): void {
    const pristine = !!this.form?.pristine;
    this.store$.dispatch(CounterpartiesActions.editCounterpartyCancel({ pristine }));
  }
}
